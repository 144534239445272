import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import ColourModeReducer from "./colourModeSlice";
import AgeGateReducer from "./ageGateSlice";
import HeaderActionsReducer from "./headerActionsSlice";
import LoginReducer from "./loginSlice";

const rootReducer = combineReducers({
  colourMode: ColourModeReducer.reducer,
  ageGate: AgeGateReducer.reducer,
  headerActions: HeaderActionsReducer.reducer,
  login: LoginReducer.reducer,
});

const persistConfig = {
  key: "glengoyne",
  version: 1,
  storage,
  whitelist: ["ageGate"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        "headerActions/setHeaderActions",
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
      ],
      ignoredPaths: ["headerActions.left.action", "headerActions.right.action"],
    },
  }),
});

export const persistor = persistStore(store);
