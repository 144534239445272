import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { StyledLink, ExternalLink } from "./common/links";
import { Body, Detail } from "./common/typography/";
import { useColourMode } from "../ducks/colourModeSlice";
import LockupDark from "../assets/images/LockupDark.png";
import LockupLight from "../assets/images/LockupLightMono.png";

import Facebook from "../assets/svg/facebook.svg";
import Twitter from "../assets/svg/twitter.svg";
import YouTube from "../assets/svg/youtube.svg";
import Pinterest from "../assets/svg/pinterest.svg";
import Instagram from "../assets/svg/instagram.svg";

const socials = [
  {
    key: "facebook",
    svg: <Facebook />,
    link: "http://www.facebook.com/GlengoyneSingleMalt",
  },
  { key: "twitter", svg: <Twitter />, link: "http://twitter.com/glengoyne" },
  {
    key: "youtube",
    svg: <YouTube />,
    link: "http://www.youtube.com/user/glengoynedistillery",
  },
  {
    key: "pinterest",
    svg: <Pinterest />,
    link: "http://www.pinterest.com/glengoynedist/glengoyne-distillery/",
  },
  {
    key: "instagram",
    svg: <Instagram />,
    link: "https://www.instagram.com/glengoyne/",
  },
];

const Footer = () => {
  const colourMode = useColourMode();
  return (
    <Root>
      <Contact>
        <Body>
          <b>Contact Us</b>
        </Body>
        <br />
        <Body>
          Glengoyne Distillery
          <br />
          Dumgoyne
          <br />
          Near Killearn
          <br />
          Glasgow, G63 9LB
          <br />
          T: +44 (0)1360 550 254
        </Body>
        <br />
        <Body>
          <ExternalLink href="mailto:reception@glengoyne.com">
            reception@glengoyne.com
          </ExternalLink>
        </Body>
      </Contact>
      <Main>
        <Image src={colourMode === "light" ? LockupLight : LockupDark} alt="" />
        <SocialIcons>
          {socials.map((social) => (
            <Icon key={social.key} href={social.link}>
              {social.svg}
            </Icon>
          ))}
        </SocialIcons>
        <ResponsibleText>
          Glengoyne Distillery encourages responsible drinking in line with SWA
          guidelines. Please adhere to the legal purchase age in your country of
          residence.
        </ResponsibleText>
        <ResponsibleText>
          For the facts about alcohol, visit{" "}
          <ExternalLink href="https://www.drinkaware.co.uk/">
            drinkaware.co.uk
          </ExternalLink>
        </ResponsibleText>
      </Main>
      <Links>
        <Body>
          <b>Useful Links</b>
        </Body>
        <ExternalLink
          href="https://www.glengoyne.com/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms &amp; Conditions
        </ExternalLink>
        <ExternalLink
          href="https://www.glengoyne.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </ExternalLink>
        {/* <StyledLink to="/enter">Sign Up</StyledLink> */}
        <StyledLink to="/login">Log In</StyledLink>
      </Links>
    </Root>
  );
};

const Root = styled.footer`
  position: relative;
  z-index: 20;
  background-color: transparent;
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.lg} 0;
  border-top: 1px solid
    ${({ theme }) => transparentize(0.8, theme.colour.fullWhite)};

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2.5fr 1fr;

  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.lg};
    padding-bottom: calc(58px + ${({ theme }) => theme.spacing.lg});
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

const Contact = styled.div`
  text-align: left;
  padding-top: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    text-align: center;
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

const SocialIcons = styled.div`
  width: 60%;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing.xl};
  }
`;

const Icon = styled.a`
  margin: 0 ${({ theme }) => theme.spacing.tiny};
  svg {
    width: 20px;
    path {
      fill: ${({ theme }) => theme.colour.fullWhite};
    }
    transition: transform 1s ease 0s;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;

const ResponsibleText = styled(Detail)`
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
`;

const Image = styled.img`
  height: 73px;
  margin: 0;
`;

const Links = styled.div`
  padding-top: ${({ theme }) => theme.spacing.lg};
  height: 100%;
  width: max-content;
  margin-left: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
`;

export default Footer;
