import styled from "styled-components";

export default styled.h2`
  font-family: "Lora";
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colour.fullWhite};
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-size: 55px;
  line-height: 70px;

  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    font-size: 38px;
    line-height: 49px;
  }
`;
