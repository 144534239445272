import { createGlobalStyle } from "styled-components";

import LoraRegular from "../assets/fonts/Lora-Regular.ttf";
import LoraMedium from "../assets/fonts/Lora-Medium.ttf";
import LoraSemiBold from "../assets/fonts/Lora-SemiBold.ttf";
import LoraBold from "../assets/fonts/Lora-Bold.ttf";

import LoraItalic from "../assets/fonts/Lora-Italic.ttf";
import LoraMediumItalic from "../assets/fonts/Lora-MediumItalic.ttf";
import LoraSemiBoldItalic from "../assets/fonts/Lora-SemiBoldItalic.ttf";
import LoraBoldItalic from "../assets/fonts/Lora-BoldItalic.ttf";

import GGNumbers from "../assets/fonts/GgNumbers-Regular.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: "GGNumbers";
        src: url(${GGNumbers}) format("opentype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Lora";
        src: url(${LoraRegular}) format("opentype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Lora";
        src: url(${LoraMedium}) format("opentype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Lora";
        src: url(${LoraSemiBold}) format("opentype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Lora";
        src: url(${LoraBold}) format("opentype");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Lora";
        src: url(${LoraItalic}) format("opentype");
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: "Lora";
        src: url(${LoraMediumItalic}) format("opentype");
        font-weight: 500;
        font-style: italic;
    }
    @font-face {
        font-family: "Lora";
        src: url(${LoraSemiBoldItalic}) format("opentype");
        font-weight: 600;
        font-style: italic;
    }
    @font-face {
        font-family: "Lora";
        src: url(${LoraBoldItalic}) format("opentype");
        font-weight: 700;
        font-style: italic;
    }
`;
