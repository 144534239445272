import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    code: null,
    email: null,
    fname: null,
    friendCount: null,
  },
  reducers: {
    loginPrimary: (state, action) => {
      return {
        ...state,
        code: action.payload.code,
        email: action.payload.email,
        fname: action.payload.fname,
        friendCount: action.payload.friendCount,
      };
    },
    loginFriend: (state, action) => {
      return {
        ...state,
        code: action.payload.code,
        email: null,
        fname: action.payload.fname,
        friendCount: action.payload.friendCount,
      };
    },
    logout: (state) => {
      return { code: null, email: null, friendCount: null };
    },
  },
});

export const { loginPrimary, loginFriend, logout } = loginSlice.actions;

export const useAuthRedirects = () => {
  const { email, code } = useSelector((state) => state.login);
  useEffect(() => {
    if (email && code) navigate("/share");
    if (code && !email) navigate("/join");
  }, [email, code]);
  return Boolean(code); // to indicate whether a redirect occurred
};

export default loginSlice;
