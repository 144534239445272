import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { navigate } from "gatsby";
import { darken, lighten } from "polished";
import { CTA } from "./typography/";
import useWindowWidth from "../../hooks/useWindowWidth";

const CTAButton = ({
  text,
  to,
  action,
  children,
  header = false,
  right = false,
  soloLeft = false,
  inverted = false,
  spaced = false,
  disabled = false,
  fullWidth = false,
}) => {
  const theme = useContext(ThemeContext);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth && windowWidth <= theme.maxMobileSize;
  return (
    <Root
      type="button"
      header={header}
      right={right}
      inverted={inverted}
      spaced={spaced}
      onClick={to ? () => navigate(to) : action}
      isEmpty={!text}
      soloLeft={soloLeft}
      disabled={disabled && !isMobile}
      fullWidth={fullWidth}
    >
      {text ? <CTA>{text}</CTA> : children}
    </Root>
  );
};

const Root = styled.button`
  position: relative;
  z-index: 170;
  cursor: pointer;
  outline: none;
  visibility: ${({ isEmpty }) => (isEmpty ? "hidden" : "visible")};
  opacity: ${({ isEmpty }) => (isEmpty ? 0 : 1)};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "126px")};
  max-width: ${({ header }) => (header ? "none" : "400px")};
  height: 58px;
  min-height: 58px;
  margin: ${({ theme, spaced }) => (spaced ? theme.spacing.lg : 0)} 0;
  border: 1px solid ${({ theme }) => theme.colour.fullWhite};
  background-color: ${({ theme, inverted }) =>
    inverted ? theme.colour.fullWhite : "transparent"};
  transition: opacity 0.5s linear 0s, background-color 0.2s ease-out 0s;

  &:disabled {
    cursor: not-allowed;
  }

  ${CTA} {
    color: ${({ theme, inverted }) =>
      inverted ? theme.colour.fullBlack : theme.colour.fullWhite};
    transition: color 0.2s ease-in 0s;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme, inverted }) =>
      inverted ? "transparent" : theme.colour.fullWhite};
    ${CTA} {
      color: ${({ theme, inverted }) =>
        inverted ? theme.colour.fullWhite : theme.colour.fullBlack};
    }
  }

  ${({ header, theme, inverted, right, soloLeft }) =>
    header
      ? `@media (max-width: ${theme.maxMobileSize}px) {
            z-index: ${header ? 250 : 170};
            position: fixed;
            bottom: 0;
            left: ${right ? "50%" : "0"};
            width: ${soloLeft ? "100vw" : "50vw"};
            background-color: ${theme.colour.fullBlack};
            border: none;
            border-top: 1px solid ${
              theme.name === "dark"
                ? darken(inverted ? 0.2 : 0.8, theme.colour.fullWhite)
                : lighten(inverted ? 0.2 : 0.8, theme.colour.fullWhite)
            };
            padding-left: 0;
            padding-right: 0;

            ${
              right
                ? `${CTA} {
                  width: 100%;
                  height: 40px;
                  line-height: 40px;
                  border-left: 1px solid ${
                    theme.name === "dark"
                      ? darken(inverted ? 0.2 : 0.8, theme.colour.fullWhite)
                      : lighten(inverted ? 0.2 : 0.8, theme.colour.fullWhite)
                  };
            }`
                : ""
            }
        }`
      : ""}
`;

export default CTAButton;
