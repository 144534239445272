import styled from "styled-components";

export default styled.h1`
  font-family: "Lora";
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colour.fullWhite};
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-size: 60px;
  line-height: 77px;

  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    font-size: 48px;
    line-height: 61px;
  }
`;
