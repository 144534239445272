import React from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

export const timeout = 700;
export const TransitionContext = React.createContext();

const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease`,
    opacity: 0,
  },
};

const Transition = ({ children, location }) => {
  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {(status) => (
          <div
            style={{
              ...getTransitionStyles[status],
            }}
          >
            <TransitionContext.Provider value={status}>
              {children}
            </TransitionContext.Provider>
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};
export default Transition;
