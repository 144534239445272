import styled from "styled-components";
import { Link } from "gatsby";

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colour.fullWhite};
  text-decoration: none;
  transition: color 1s ease 0s;
  &:hover {
    color: ${({ theme }) => theme.colour.sigPeach};
    text-decoration: none;
  }
`;

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colour.fullWhite};
  text-decoration: none;
  transition: color 1s ease 0s;
  &:hover {
    color: ${({ theme }) => theme.colour.sigPeach};
    text-decoration: none;
  }
`;
