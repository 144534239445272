import { useEffect } from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { timeout } from "../layouts/transition";

const colourModeSlice = createSlice({
  name: "colourMode",
  initialState: "dark",
  reducers: {
    setModeDark: () => "dark",
    setModeLight: () => "light",
  },
});

const { setModeDark, setModeLight } = colourModeSlice.actions;

export const useDarkMode = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setModeDark());
    }, timeout);
  }, [dispatch]);
};

export const useLightMode = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setModeLight());
    }, timeout);
  }, [dispatch]);
};

export const useColourMode = () => {
  return useSelector((state) => state.colourMode);
};

export default colourModeSlice;
