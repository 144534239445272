// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-age-js": () => import("./../../../src/pages/age.js" /* webpackChunkName: "component---src-pages-age-js" */),
  "component---src-pages-enter-js": () => import("./../../../src/pages/enter.js" /* webpackChunkName: "component---src-pages-enter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */)
}

