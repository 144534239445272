import React from "react";

import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "../ducks/";
import { PersistGate } from "redux-persist/integration/react";
import { useColourMode } from "../ducks/colourModeSlice";
import { useAgeGate } from "../ducks/ageGateSlice";

import styled, { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./theme";
import GlobalFonts from "./fonts";
import GlobalStyles from "./globalStyles";
import Transition from "./transition";

import Header from "../components/header";
import Footer from "../components/footer";

const Layout = ({ children, location }) => {
  const colourMode = useColourMode();
  const isAgeGate = /^\/age/.test(location.pathname);
  useAgeGate(location.pathname);
  return (
    <ThemeProvider theme={colourMode === "light" ? lightTheme : darkTheme}>
      <GlobalFonts />
      <GlobalStyles />
      <Transition location={location}>
        <Root location={location}>
          <Header />
          <Main>{children}</Main>
          {!isAgeGate && <Footer />}
        </Root>
      </Transition>
    </ThemeProvider>
  );
};

const WrappedLayout = ({ children, location }) => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Layout location={location}>{children}</Layout>
    </PersistGate>
  </ReduxProvider>
);

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colour.fullBlack};
  background-image: ${({ theme, location }) =>
    /^\/share|^\/join/.test(location.pathname)
      ? `linear-gradient(to bottom, ${theme.colour.fullBlack} 0%, ${theme.colour.blackGrad} 100%)`
      : "none"};
`;

const Main = styled.main``;

export default WrappedLayout;
