const spacing = {
  tiny: "4px",
  xxs: "8px",
  xs: "12px",
  sm: "16px",
  md: "20px",
  lg: "32px",
  xl: "40px",
  xxl: "60px",
  mega: "100px",
};

const breakpoints = {
  xs: 360,
  sm: 470,
  md: 769,
  lg: 1023,
  xl: 1290,
  xxl: 1590,
};

const darkColours = {
  fullBlack: "#000000",
  lightBlack: "#555555",
  grey: "#C4C4C4",
  blackGrad: "#1E1E1E",
  whiskyOrange: "#F7A43A",
  darkWhisky: "#AF4D0E",
  sigPeach: "#EDC686",
  bgBeige: "#F0EEE8",
  fullWhite: "#FFFFFF",
};

const lightColours = {
  fullBlack: darkColours.bgBeige,
  lightBlack: darkColours.fullWhite,
  grey: darkColours.lightBlack,
  blackGrad: darkColours.blackGrad,
  whiskyOrange: darkColours.sigPeach,
  darkWhisky: darkColours.darkWhisky,
  sigPeach: darkColours.whiskyOrange,
  bgBeige: darkColours.grey,
  fullWhite: darkColours.fullBlack,
};

const theme = { spacing, breakpoints, maxMobileSize: breakpoints.lg };

export const darkTheme = { ...theme, name: "dark", colour: darkColours };
export const lightTheme = { ...theme, name: "light", colour: lightColours };
