import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby";

const ageGateSlice = createSlice({
  name: "ageGate",
  initialState: {
    hasPassed: false,
    intendedDestination: "/",
    error: false,
  },
  reducers: {
    acceptAgeGate: (state, action) => {
      const thisYear = new Date().getFullYear();
      const eighteenYearsAgo = thisYear - 18;
      const hasPassed =
        Number(action.payload.year) >= 1833 &&
        Number(action.payload.year) <= eighteenYearsAgo;
      const error = !hasPassed;
      return { ...state, hasPassed, error };
    },
    gotoAgeGate: (state, action) => {
      return { ...state, intendedDestination: action.payload.from };
    },
    clearAgeGateError: (state) => {
      return { ...state, error: false };
    },
  },
});

export const {
  acceptAgeGate,
  gotoAgeGate,
  clearAgeGateError,
} = ageGateSlice.actions;

export const useAgeGate = (from) => {
  const hasPassed = useSelector((state) => state.ageGate.hasPassed);
  const dispatch = useDispatch();

  const isAgeGate = /^\/age/.test(from);
  if (isAgeGate) return true;

  if (!hasPassed) {
    dispatch(gotoAgeGate({ from }));
    navigate("/age");
  }
};

export default ageGateSlice;
