import React from "react";
import styled from "styled-components";
import { useColourMode } from "../ducks/colourModeSlice";
import { useHeaderActions } from "../ducks/headerActionsSlice";
import CTAButton from "./common/cta-button";
import { StyledLink } from "./common/links";
import LockupDark from "../assets/images/LockupNoTaglineDark.png";
import LockupLight from "../assets/images/LockupNoTaglineLightMono.png";

const Header = () => {
  const colourMode = useColourMode();
  const buttons = useHeaderActions();
  return (
    <Root colourMode={colourMode}>
      <CTAButton
        header
        soloLeft={!buttons.right}
        text={buttons?.left?.text}
        to={buttons?.left?.to}
        action={buttons?.left?.action}
      />
      <StyledLink to="/">
        <Image src={colourMode === "light" ? LockupLight : LockupDark} alt="" />
      </StyledLink>
      <CTAButton
        header
        right
        text={buttons?.right?.text}
        to={buttons?.right?.to}
        action={buttons?.right?.action}
      />
    </Root>
  );
};

const Root = styled.header`
  position: absolute;
  top: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.maxMobileSize}px) {
    justify-content: center;
  }
`;

const Image = styled.img`
  height: 97px;
  margin: 0;
  position: relative;
  top: -12px;
`;

export default Header;
