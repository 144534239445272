import styled from "styled-components";

export default styled.p`
  font-family: "Lora";
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colour.fullWhite};
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-size: 16px;
  line-height: 20px;
`;
