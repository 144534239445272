import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { timeout } from "../layouts/transition";

const headerActionsSlice = createSlice({
  name: "headerActions",
  initialState: {
    left: null,
    right: null,
  },
  reducers: {
    setHeaderActions: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

const { setHeaderActions } = headerActionsSlice.actions;

export const useHeaderActions = () =>
  useSelector((state) => state.headerActions);

export const useUpdateHeaderActions = (newActions, redundant) => {
  const dispatch = useDispatch();
  useEffect(() => {
    !redundant &&
      setTimeout(() => {
        dispatch(setHeaderActions(newActions));
      }, timeout);
  }, [dispatch, newActions, redundant]);
};

export default headerActionsSlice;
